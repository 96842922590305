@import 'abstracts/variables';
@import 'abstracts/droppe-theme';
@import 'MultiStepModal.module.less';

.mobile-modal-no-width {
  @media @below-lg {
    top: 5%; // Align the modal more towards the center to match the design
  }

  // Remove the width for the contacts modal in the mobile blocker for smaller devices
  @media @below-sm {
    width: unset !important;
  }
}

.modal-container {
  z-index: @z-index-modal;

  :global {
    .rs-modal-content {
      padding: @modal-content-padding + @spacer;

      @media @below-lg {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: none;
      }

      // Remove extra padding for extra-small device screen size
      @media @below-sm {
        padding: @spacer * 3;
      }
    }

    .rs-modal-body {
      max-height: 100% !important; // Needed for full height of content in modals
      overflow: visible !important; // Show validation tooltips when input at bottom
      margin-top: 0;
      padding-bottom: 0;
    }
  }

  &.contact-modal {
    :global {
      .rs-modal-header {
        .rs-modal-header-close {
          right: @modal-content-padding + 13px; // Magic number to align with UI design

          @media @below-lg {
            right: @spacer * 2; // Magic number to align with UI design
            width: @spacer * 4;
          }

          @media @below-md {
            right: 15px; // Magic number to align with UI design for extra small device screens
            top: 30px; // Align the close button to header on small screen
          }
        }

        h4 {
          @media @below-lg {
            font-size: @font-size-base * 2;
          }
        }
      }

      .rs-panel {
        cursor: pointer;

        // Give the panel boxes a fixed height and force center the content
        @media @below-lg {
          height: @spacer * 12;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .rs-panel-body {
          @media @below-lg {
            padding: @spacer * 2 @spacer;
            width: 100%;

            .rs-flex-box-grid {
              flex-wrap: nowrap;

              svg {
                overflow: visible; // Fix overflow issue due to padding on small screen sizes
              }
            }
          }
        }
      }
    }
  }

  &.supplier-contact-modal {
    min-width: @modal-md + 20px; // Stop phone number from wrapping to next line

    :global {
      .rs-btn {
        padding: @spacer * 1.5;
      }
    }

    @media @below-sm {
      min-width: auto;
    }
  }

  .error-message {
    color: @red;
  }

  button:global(.rs-panel-body) {
    padding: @spacer * 3 35px @spacer * 3; // Match Figma design
  }

  .modal-panel-content {
    flex-grow: 1;
    padding: 0 @spacer * 3; // Match Figma design

    @media @below-lg {
      padding: 0 @spacer * 2;
    }

    @media @below-sm {
      padding: 0 @spacer;
    }

    p {
      display: inline;
      font-family: @headings-font-family; // Match Figma font
      color: @B600; // Match Figma color
      font-weight: 500; // Match Figma font weight
    }

    .modal-panel-link-style {
      font-family: @headings-font-family; // Match Figma font
      font-weight: 500; // Match Figma font weight

      // On small screens, break the line of the following <p/> element
      @media @below-sm {
        &::after {
          content: '\a';
          white-space: pre;
        }
      }
    }
  }

  .modal-panel-link:hover,
  .modal-panel-link:active {
    text-decoration: none; // Remove underlines from all text on hover
  }

  :global(.rs-panel):hover {
    border-color: @primary-darker;

    .next-arrow-icon {
      fill: @primary-darker;
    }

    .modal-panel-link-style {
      text-decoration: underline; // Only underline links on hover
    }
  }
}
