@import 'abstracts/variables';
@import 'abstracts/helpers';
@import 'abstracts/spacers';
@import 'MobileBlocker.module.less';

.modal {
  margin: 0;
  height: 100vh;
  // Removing 15px to solve the shakiness problem of the modal
  width: calc(100vw - 15px);
  max-width: 100%;
  display: grid !important; // Overiding inline style by rsuite
  place-items: center;
  z-index: @z-index-modal;

  label {
    font-weight: 600;
  }

  :global {
    .rs-modal-body {
      max-height: none !important; // Overiding inline style by rsuite
      padding: 0;
      margin: 0;

      // To fix the box shadow issue on focus of input fields. Also `!important` for Overiding inline style by rsuite
      overflow: visible !important;
    }

    .rs-modal-content {
      padding: @spacer * 3 @spacer * 4;
      padding-bottom: @spacer * 2;
    }

    // Moving the radio icons to the left
    .rs-radio-checker {
      padding-left: (@radio-sense-width + @radio-width-height);
    }

    .rs-radio-wrapper {
      .margin-left-spacer-negative;
    }

    @media @below-sm {
      width: 100vw;

      .rs-modal-content {
        height: 100%;
        padding: @spacer * 2;
      }

      .rs-modal-dialog {
        width: 100vw;
        height: 100%;
      }
    }
  }

  :global(.rs-modal-header) {
    text-align: center;
    position: relative;

    :global(.rs-modal-header-close) {
      line-height: 0;
      color: @headings-color;
      top: 0;
      right: 0;
      z-index: 1;
    }
  }

  .input-label {
    font-weight: 600;
    margin-top: @spacer * 2.5;
  }

  .sub-input-label {
    color: @B600;
    margin-bottom: @spacer;
  }

  .textarea-input {
    height: @spacer * 7;
    margin-top: @spacer;
  }

  .picker-input {
    cursor: pointer;

    :global(.rs-picker-tag-wrapper) {
      color: @primary-dark;
      font-weight: 500;
      padding-left: @padding-x;

      .selected-item {
        margin-top: @padding-y;
        margin-right: @spacer * 0.5;
        display: inline-block;
      }

      :global {
        .rs-picker-search .rs-picker-search-input {
          padding-left: 0;
        }
      }
    }

    :global(.rs-picker-toggle.rs-btn) {
      .rs-picker-toggle-value {
        text-transform: capitalize;
      }
    }
  }

  & ~ .tagpicker-input-menu {
    height: 215px;
    width: 250px;
    min-width: 250px !important; // Overriding inline style
  }

  & ~ .picker-input-menu {
    text-transform: capitalize;
    z-index: @z-index-modal;
  }

  .form-button {
    font-family: @font-family-base;

    &.form-primary-button {
      margin-bottom: @spacer;
    }
  }

  @media @below-sm {
    .textarea-input {
      height: @spacer * 10;
    }

    .number-input {
      margin-bottom: @spacer;
    }

    .form-button {
      padding: @spacer * 1.5 0;

      &.form-primary-button {
        margin-bottom: @spacer * 1.5;
      }
    }

    .form-title {
      &.in-confirmed-state {
        margin-top: @spacer * 12;
      }
    }
  }

  .form-title {
    line-height: @line-height-base;
  }
}

.modal-dialog {
  width: @modal-md;
}

.modal-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  padding: 0 0 @spacer * 3 0;
  margin: 0;

  .completed-item {
    text-decoration: underline;
  }

  .active-item {
    color: @primary-dark;
    text-decoration: underline;
  }

  @media @below-sm {
    max-width: none;
  }
}

.modal-backdrop {
  .modal-backdrop-style();
}
